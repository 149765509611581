var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.show,"variant":"transparent","opacity":"0.99","blur":"5px","rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-icon-controller',{attrs:{"font-scale":"3","animation":"cylon"}}),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v(" กรุณารอสักครู่... ")])],1)]},proxy:true}])},[_c('div',[_c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('a',{attrs:{"href":"/"}},[_c('h2',{staticClass:"font-weight-bolder text-center"},[_vm._v(" AK Admin ")])]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref$1){
var invalid = ref$1.invalid;
return [_c('b-form',{staticClass:"auth-login-form",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',[_c('label',{attrs:{"for":"Username"}},[_vm._v("ชื่อผู้ใช้งาน")]),_c('validation-provider',{attrs:{"name":"Username","vid":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Username","state":errors.length > 0 ? false : null,"name":"Username"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'กรอกชื่อผู้ใช้งานให้ถูกต้อง' : ''))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"login-password"}},[_vm._v("รหัสผ่าน")]),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.GetOTP($event)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'กรอกรหัสผ่านให้ถูกต้อง' : ''))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('span',[_vm._v("จดจำฉันไว้ในระบบ")])])],1),(_vm.nextstep === 0)?_c('b-button',{attrs:{"variant":"primary","block":"","disabled":invalid},on:{"click":function($event){return _vm.GetOTP()}}},[_vm._v(" ขอ OTP ")]):_vm._e(),(_vm.nextstep === 1)?_c('b-form-group',{attrs:{"label":"OTP","label-for":"OTP"}},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(" ref : "+_vm._s(_vm.ref)+" ")]),_c('validation-provider',{attrs:{"name":"OTP","vid":"OTP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"OTP","state":errors.length > 0 ? false : null,"name":"OTP","placeholder":"กรุณากรอก OTP"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.CheckOTP($event)}},model:{value:(_vm.OTP),callback:function ($$v) {_vm.OTP=$$v},expression:"OTP"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.nextstep === 1)?_c('b-button',{attrs:{"variant":"primary","block":"","disabled":invalid},on:{"click":function($event){return _vm.CheckOTP()}}},[_vm._v(" เข้าสู่ระบบ ")]):_vm._e()],1)]}}])})],1)],1)]),_c('img',{staticClass:"img-login",attrs:{"src":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAACtBAMAAAC0KMWCAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAVUExURUdwTPHx9vDx9vDx9vHy9/Pz+e/w9XrTQnAAAAAGdFJOUwB7ptFPJqEDGaYAAAKASURBVHja7d3NacNAFIVRb1KAUoJUgkB7g0qIW1D/JcSEkEVwyJ/BulfnlKB8DO/NGHI6QYHLyzoM4zT7EuR6uqzr8HzNePvgoxB4GJ+HcZyX7Qafh5DD+H2muJmxoEmZKc7XmWKet5/yzciaKQRN6oK3CZq0w/jXM4WgSV3wBE3Vgido9rjgDX9d8ARN1YInaHaQ8fyAjAXNbu8pBE3VPYWg6R+NBU3qPYWgOeZhLGi+P4zffhUUl7Gg+TxTTCEzhaD5YqbY022boPnPTLFsrfyNLXiCJuEwLp0pBH2s07hpwRP04S8qtsPTQvxFxbTIWND5FxXaFXT+RYXDWNAFjx8yFrQFT9CYKQSNBU/QRz+MzRSCzj+MLXiCNlMg6P284MlY0AWHsZlC0OELnsNY0F7wEPROXvBkLOj8FzwzhaAteAjaCx6CvseCJ2NBmykQtAUPQd/jV0EyFnTBgmemELQFD0E//AXPgkdy0BY8CoJ220ZB0BY88oO24FEQtJmC/KAteBQE7QWP/KC94FEQtJmCgqC94JEftMOYgqAL/kc0grbgkR+0FzwKgvaCR37QFjwKgvaCR0HQFjw6gjZTUBW0T4CgQdAgaBA0ggZBg6BB0CBoBA2CBkGDoEHQCBoEDYIGQYOgETQIGgQNggZBI2gQNAgaBA2CRtAgaBA0CBoEjaBB0CBoEDSCBkGDoEHQIGgEDYIGQYOgQdAIGgQNggZBg6ARNAgaBA2CBkEjaBA0CBoEDYJG0CBoEDQIGgSNoEHQIGgQNAgaQYOgQdAgaAQNggZBg6BB0AgaBA2CBkGDoBE0CBoEDYIGQSNoEDQIGgQNgkbQIGgQNAgaBE2rV8fAx8SI153KAAAAAElFTkSuQmCC","alt":""}})])])}
var staticRenderFns = []

export { render, staticRenderFns }